import * as XLSX from 'xlsx'

export const downloadTableData = (inputData) => {
  const sheetData = inputData.map((item) => ({
    group: item?.group_title,
    label: item?.label,
    'connection status': item?.state_gps_connection_status?.replace('_', ' '),
    'movement status': item?.state_gps_movement_status,
    odometer: item?.odometer,
    'engine hours': item?.engine_hours,
    'GPS address': item?.address,
    'GPS location': item?.state_gps_location,
    'GPS signal level': item?.state_gps_signal_level,
    'GPS update': item?.state_gps_updated,
    heading: item?.state_gps_heading,
    speed: item?.state_gps_speed,
    altitude: item?.state_gps_alt,
    'GSM update': item?.state_gsm_updated,
    'GSM signal level': item?.state_gsm_signal_level,
    'GSM network name': item?.state_gsm_network_name,
    'battery level': item?.state_battery_level,
    'battery update': item?.state_battery_update,
    'input 1': item?.input_1,
    'input 2': item?.input_2,
    'input 3': item?.input_3,
    'input 4': item?.input_4,
    'input 5': item?.input_5,
    'inputs update': item?.state_inputs_update,
    'output 1': item?.output_1,
    'output 2': item?.output_2,
    'output 3': item?.output_3,
    'output 4': item?.output_4,
    'outputs update': item?.state_outputs_update,
    'last update': item?.lastUpdate
  }))
  const sheet = XLSX.utils.json_to_sheet(sheetData)
  const workBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workBook, sheet, 'Vehciles')
  XLSX.writeFile(workBook, 'GMM vehicles.xlsx')
}